<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    en: {
    },
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      list: null,
    };
  },
  methods: {
    getList() {
      api
        .get("giftcards")
        .then((response) => {
          if (response.data.status == "success") {
            this.list = response.data.list;
          }
        })
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Gift Cards</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div v-if="!list" class="card">
      <div class="card-body text-center">
        <b-spinner small class="ml-2 align-middle" variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-if="list && list.length == 0" class="card">
      <div class="card-body text-center">
        Nenhum gift card encontrado.
      </div>
    </div>

    <template v-else>
      <div class="giftcards">
        <router-link class="giftcard" tag="a" :to="'/giftcards/' + card.slug" v-for="(card, index) in list" :key="index">
          <div class="card">
            <div class="card-body p-0 text-center">
              <img class="w-100 rounded-top" :src="card.cover" /><br>
              <h6 class="p-4 m-0">{{ card.name }}</h6>
            </div>
          </div>
        </router-link>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.giftcards {
  display: flex;
  flex-flow: row wrap;
  gap: 1%;
}

.giftcard {
  flex-basis: 15.6%;
}

.giftcard .card {
  border-radius: 20px;
  border: 1px solid #DEDFE4;
  padding: 1px;
}

.giftcard .rounded-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

@media (max-width: 1100px) {
  .giftcards {
    gap: 2%;
  }

  .giftcard {
    flex-basis: 23.5%;
  }
}

@media (max-width: 500px) {
  .giftcards {
    gap: 2%;
  }

  .giftcard {
    flex-basis: 49%;
  }
}
</style>